import React from "react";
import cx from "classnames";
import styles from "./Card.module.css";
import mockImage from "../../assets/null.svg";
import { SimpleCardInfo } from "../../utils/types";

const SimpleCard = ({ image, about, url }: SimpleCardInfo): JSX.Element => {
  return (
    <div className={cx(styles.cardContainer, styles.simpleCard)}>
      <a href={url} className={styles.linkContainer} target="_blank" rel="noreferrer">
        <p>{about}</p>
        <div className={styles.safariSpecialImg}>
          <img src={image || mockImage} alt="" />
        </div>
      </a>
    </div>
  );
};

export default SimpleCard;
