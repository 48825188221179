import { waitForDelay } from "./utlils";

declare let window: any;
let gtag;
let initGA = false;
let gaReady = false;

const GAID = "G-HBMGCT8YXE";
const debugMode = true;

export const initGoogleAnalytics4 = (): void => {
  console.log("init ga");
  if (initGA) return;
  initGA = true;
  const head: HTMLHeadElement = document.getElementsByTagName("head")[0];
  const scriptGtag: HTMLScriptElement = document.createElement("script");
  const scriptDataLayers: HTMLScriptElement = document.createElement("script");
  scriptGtag.setAttribute("src", `https://www.googletagmanager.com/gtag/js?id=${GAID}`);
  scriptDataLayers.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', '${GAID}',{'debug_mode':${debugMode}});
    `;
  head.appendChild(scriptGtag);
  head.appendChild(scriptDataLayers);
  prepareGA();
};

export const prepareGA = async () => {
  if (!initGA) initGoogleAnalytics4();
  while (gtag === undefined) {
    // eslint-disable-next-line no-await-in-loop
    await waitForDelay(0.5);
    gtag = window.gtag;
  }
  gaReady = true;
  return gaReady;
};

export const userAction = async (clickedOn) => {
  await prepareGA();
  gtag("event", "Action", {
    click: clickedOn,
  });
};
