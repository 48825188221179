import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import styles from "./App.module.css";

import MainPage from "./MainPage/MainPage";
import { initGoogleAnalytics4 } from "./utils/gaHelper";

function App() {
  initGoogleAnalytics4();
  return (
    <div>
      <MainPage />
      <Footer />
    </div>
  );
}

export default App;
