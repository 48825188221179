/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React from "react";
import styles from "./Footer.module.css";

const Footer = (): JSX.Element => {
  return (
    <div className={styles.footer}>
      <p>Developed by IT ©2021.</p>
    </div>
  );
};

export default Footer;
