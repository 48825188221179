/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useState } from "react";
import cx from "classnames";
import styles from "./MainPage.module.css";
import profilePhoto from "../assets/profilePhoto.jpg";
import { CardInfo, SimpleCardInfo } from "../utils/types";
import Card from "../Components/Card/Card";
import SimpleCard from "../Components/Card/SimpleCard";
import bbtImg from "../assets/bbt.png";
import housingImg from "../assets/housing.jpg";
import mapImage from "../assets/map.jpg";
import suncraftImage from "../assets/suncraftLogo.png";
import handmade from "../assets/handmade.jpg";
import bbtReport from "../assets/bbt.pdf";
import heartImage from "../assets/heart_small.jpg";

const ML_SHOWCASE_INFO: CardInfo[] = [
  {
    id: 3,
    title: "Heart disease prediction",
    about: "The goal of this case is to find trends in heart data to predict certain cardiovascular events.",
    image: heartImage,
    reportFile: "",
    codeUrl: "https://colab.research.google.com/drive/1bmc0g37FU96sTe0s7xOmN_lWGrXRpbjq?usp=sharing",
    mediumUrl: "https://medium.com/@miasxyue/heart-disease-prediction-e0ea9438265",
  },
  {
    id: 1,
    title: "Toronto Housing",
    about:
      "With machine learning knowledge, I'll build an end to end solution that predicts property prices based on available features.",
    image: housingImg,
    reportFile: "",
    codeUrl: "https://github.com/Mia-Tay/collab_housing",
    mediumUrl: "https://medium.com/@miasxyue/housing-price-prediction-with-machine-learning-9bb142ec4d4c",
  },
  {
    id: 2,
    title: "Bubble Tea",
    about: "Bubble tea shops' location selection with data science.",
    image: bbtImg,
    reportFile: bbtReport,
    codeUrl: "https://github.com/Mia-Tay/collab_bubble_tea",
  },
];

const ENTREPRENEUR_SHOWCASE_INFO: SimpleCardInfo[] = [
  {
    id: 1,
    about: "Shopify - craft shop: Design and customized e-commerce site focus with craft kit.",
    image: suncraftImage,
    url: "https://suncraftgifts.com/",
  },
  {
    id: 2,
    about:
      "Collaborated with designer and create our own scratch map. Manufactured with high quality and selling in USA, Canada and Australia",
    image: mapImage,
    url: "https://www.etsy.com/ca/shop/SuncraftGiftsToronto",
  },
  {
    id: 3,
    about: "Handmade touch - little  store filled with old fashion. ",
    image: handmade,
    url: "https://www.etsy.com/ca/shop/SuncraftGiftsFashion",
  },
];

const MainPage = (): JSX.Element => {
  const [showEmail, setShowEmail] = useState(false);
  return (
    <>
      <div className={cx(styles.row, styles.firstIntoRow)}>
        <p>
          <span>Hello</span>, a bit about me: <br /> data enthusiast, part-time entrepreneur, supply chain management
          expert
        </p>
        <img src={profilePhoto} alt="profile" />
      </div>
      <div className={cx(styles.row, styles.secondMlRow)}>
        <h2>Machine Learning cases</h2>
        <div className={styles.cardsRow}>
          {ML_SHOWCASE_INFO.map((cardInfo) => (
            <div key={cardInfo.id} className={styles.cardWrapper}>
              {Card(cardInfo)}
            </div>
          ))}
        </div>
      </div>

      <div className={cx(styles.row, styles.thirdEntrepreneurRow)}>
        <h2>Entrepreneur Journey</h2>
        <div className={styles.cardsRow}>
          {ENTREPRENEUR_SHOWCASE_INFO.map((cardInfo) => (
            <div key={cardInfo.id} className={styles.cardWrapper}>
              {SimpleCard(cardInfo)}
            </div>
          ))}
        </div>
      </div>

      <div className={cx(styles.row, styles.fourthContactsRow)}>
        <h2>Connect</h2>
        <div className={styles.contacts}>
          <div>
            <p>Find me at LinkedIn: </p>{" "}
            <a href="https://www.linkedin.com/in/mia-sun-ca/"> https://www.linkedin.com/in/mia-sun-ca/</a>
          </div>
          <div>
            <p>Send me email at: </p>
            {showEmail ? (
              <a href="mailto:miasun90@gmail.com">miasun90(at)gmail.com</a>
            ) : (
              <button className={styles.revealButton} type="button" onClick={() => setShowEmail(true)}>
                click to reveal
              </button>
            )}
          </div>
        </div>
      </div>
      {/* <div className={cx(styles.row, styles.thirdEntrepreneurRow)}>HireMe</div>; */}
    </>
  );
};

export default MainPage;
