import React from "react";
import cx from "classnames";
import styles from "./Card.module.css";
import mockImage from "../../assets/null.svg";
import codeIcon from "../../assets/codeIcon.png";
import pdfIcon from "../../assets/pdfIcon.svg";
import mediumIcon from "../../assets/mediumIcon.svg";
import { CardInfo } from "../../utils/types";

const Card = ({ title, image, about, codeUrl, reportFile, mediumUrl }: CardInfo): JSX.Element => {
  return (
    <div className={cx(styles.cardContainer, styles.codeCard)}>
      <h3>{title}</h3>
      <div className={styles.imgRow}>
        <div className={styles.safariSpecialImg}>
          <img src={image || mockImage} alt="" />
        </div>
        <p>{about}</p>
      </div>

      <div className={styles.linksRow}>
        <a
          href={mediumUrl || reportFile}
          className={styles.link}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => {
            if (reportFile) {
              e.preventDefault();
              window.open(reportFile);
            }
          }}
        >
          {mediumUrl ? <img src={mediumIcon} alt="" /> : <img src={pdfIcon} alt="" />}
          Report
        </a>

        {codeUrl && (
          <a href={codeUrl} className={styles.link} target="_blank" rel="noreferrer">
            <img src={codeIcon} alt="" />
            Code
          </a>
        )}
      </div>
    </div>
  );
};

export default Card;
